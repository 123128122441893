import { fetchWithoutToken, fetchWithToken, fetchWithoutTokenPost } from "../../helpers/fetch";
import { types } from "../../types/types";
import { loadingModal } from "../../helpers/UseSwal";
import Swal from "sweetalert2";
import { PartyMode } from "@material-ui/icons";

export const accountersStartLoading = () => {
  return async (dispatch) => {
    const resp = await fetchWithToken('api/Accounting/accounters');
    const body = await resp.json();
    if (body.success) {
      dispatch(accountersLoaded(body.data))
    }
  }
}

const accountersLoaded = (accounters) => ({
  type: types.accounterStartLoading,
  payload: accounters
})

export const preReportsUpgradeApi = (pageNumber, params) => {
  let api = ""
  if (pageNumber != null) {
    api = `/api/Admin/v2/clients/StatementBalance?PageSize=100&PageNumber=${pageNumber}`
  } else {
    api = `/api/Admin/v2/clients/StatementBalance?PageSize=100&PageNumber=1&Params=${params}`
  }
  return async (dispatch) => {
    loadingModal(true);

    try {
      const resp = await fetchWithoutToken(
        api,
        null,
        'POST'
      );


      const body = await resp.json();

      loadingModal(false);

      if (body.success) {
        dispatch(preReportsData(body.data));
      } else {
        Swal('Error al obtener datos', null, 'error');
      }
    } catch (error) {
    }
  };
};


const preReportsData = (data) => ({
  type: types.preReportUpgrade,
  payload: data
});


export const preReportsDetails = (profileId, taxRegimen) => {
  return async (dispatch) => {
    loadingModal(true);

    try {
      const resp = await fetchWithoutToken(
        `/api/Admin/v2/clients/GetDetailByProfileRegime?profileId=${profileId}&regimeId=${taxRegimen}`,
        null,
        'POST'
      );

      const body = await resp.json();

      loadingModal(false);

      if (body.success) {
        dispatch(preReportsDataDetails(body.data));
      } else {
        Swal('Error al obtener datos', null, 'error');
      }
    } catch (error) {
    }
  };
};


const preReportsDataDetails = (data) => ({
  type: types.preReportDetailsData,
  payload: data
});

// const preReportsData = (data) => ({
//   type: types.preReportUpgrade,
//   payload: data
// });

export const monitorDownload = (pageNumber, params) => {
  let api = ""
  if (pageNumber != null) {
    api = `api/DownloadXmlSAT/v2/Monitor?PageSize=100&PageNumber=${pageNumber}`
  } else {
    api = `api/DownloadXmlSAT/v2/Monitor?RFC=${params}`
  }
  return async (dispatch) => {
    loadingModal(true);

    try {
      const resp = await fetchWithoutToken(
        api,
        null,
        'POST'
      );

      const body = await resp.json();

      loadingModal(false);

      if (body.success) {
        dispatch(monitorDataDownload(body.data));
      } else {
        Swal('Error al obtener datos', null, 'error');
      }
    } catch (error) {
    }
  };
};

const monitorDataDownload = (data) => ({
  type: types.monitorDownloadData,
  payload: data
});



export const additionalProcedures = (pageNumber, params) => {
  let api = ""
  if (pageNumber != null) {
    api = `api/Monitors/AditionalProcedures/Get?PageSize=100&PageNumber=${pageNumber}`
  } else {
    api = `api/Monitors/AditionalProcedures/Get?Params=${params}`
  }
  return async (dispatch) => {
    loadingModal(true);

    try {
      const resp = await fetchWithToken(
        api,
        null,
        'GET'
      );

      const body = await resp.json();

      loadingModal(false);

      if (body.success) {
        dispatch(additionalDataProcedures(body.data));
      } else {
        Swal('Error al obtener datos', null, 'error');
      }
    } catch (error) {
    }
  };
};

const additionalDataProcedures = (data) => ({
  type: types.additionalProceduresData,
  payload: data
});



export const diagnosticApi = (pageNumber, params) => {
  let api = ""
  if (pageNumber != null) {
    api = `api/Monitors/Diagnostics/Get?PageSize=100&PageNumber=${pageNumber}`
  } else {
    api = `api/Monitors/Diagnostics/Get?Params=${params}`
  }
  return async (dispatch) => {
    loadingModal(true);

    try {
      const resp = await fetchWithToken(
        api,
        null,
        'GET'
      );

      const body = await resp.json();

      loadingModal(false);

      if (body.items != []) {
        dispatch(diagnosticDataApi(body.items));
      } else {
        Swal('Error al obtener datos', null, 'error');
      }
    } catch (error) {
    }
  };
};

const diagnosticDataApi = (data) => ({
  type: types.diagnosticApiData,
  payload: data
});

export const paymentApi = (pageNumber, params) => {
  let api = ""
  if (pageNumber != null) {
    api = `api/Monitors/Payments/Get?PageSize=10&PageNumber=${pageNumber}`
  } else {
    api = `api/Monitors/Payments/Get?Params=${params}`
  }
  return async (dispatch) => {
    loadingModal(true);

    try {
      const resp = await fetchWithToken(
        api,
        null,
        'GET'
      );

      const body = await resp.json();

      loadingModal(false);

      if (body.items != []) {
        dispatch(paymentDataApi(body.items));
      } else {
        Swal('Error al obtener datos', null, 'error');
      }
    } catch (error) {
    }
  };
};

const paymentDataApi = (data) => ({
  type: types.paymentApiData,
  payload: data
});