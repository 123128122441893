import React, { useEffect, Fragment, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import { useForm } from '../../../../../hooks/useForm'
import { productToSalesStartLoading } from '../../../../../actions/catalogs/productToSales';
import { codeReferencePromotion, generatePaymentPreSale } from '../../../../../actions/crm/preSales';
import { ModalContext } from '../../../../modal/ModalContext';

export const ListProduct = ({presale}) => {

    const dispatch = useDispatch();
    
    const { handleModal } = useContext(ModalContext);

    const [msgErrors, setMsgErrors] = useState({});
    const [msgErrorsCode, setMsgErrorsCode] = useState({});

    const [isActive, setIsActive] = useState(true)

    const [promotionCodeProduct, setPromotionCodeProduct] = useState('')
    const [promotionCodeReference, setPromotionCodeReference] = useState('')
    const [prodcutToSalePromotionId, setProdcutToSalePromotionId] = useState('')

    const [idPromotion, setIdPromotion] = useState('')
    const [dataPromotion, setDataPromotion ] = useState('')

    const [isActiveModal, setIsActiveModal ] = useState(false)
    
    const { formValues, handleInputChange, handleInputChangedByReferenceCode } = useForm({
        productId: '',
        referenceCode: '',
        availableProduct: ''
    }
    );
    const { productId, referenceCode, availableProduct } = formValues;
    
    const {productToSales} = useSelector(state => state.productToSale);
    const {crmPromotions } = useSelector(state => state.crmPromotion);

    const {name,lastName} = presale

    useEffect(() => {
        if (productToSales.length === 0) {
          dispatch(productToSalesStartLoading());
        }
    }, [dispatch, productToSales]);
    
    
    const handleInputChangePromotion = ({ target }) => {
        const { name, value} = target;
        const selectedPromotionId = target.value;

        const selectedPromotion = crmPromotions.promotionsToApply.find(
            (promotion) => promotion.id == selectedPromotionId
        );
        setDataPromotion(selectedPromotion)
        if( name === 'idPromotion' ) {
            setIdPromotion( { ...formValues, [name]: value })
        }
    }

    const handleRefresPromotion = () => {
        if( dataPromotion === undefined || dataPromotion === '') {
            return (
                <div>
                    <div className='row mt-3'>
                        <div className='col-4 text-center'>
                            <label> Precio original: </label> <br/> 
                            <label className='text-primary text-center'> ${crmPromotions.originalPrice.toFixed(2)} </label>
                        </div>
                        <div className='col-4 text-center'>
                            <label> Descuento de: </label><br/> 
                            <label className='text-primary text-center'> {crmPromotions.discount}% </label>
                        </div>
                        <div className='col-4 text-center'>
                            <label> Total link: </label><br/> 
                            <label className='text-primary text-center'> ${crmPromotions.totalDiscount.toFixed(2)} </label>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-4 text-center'>
                            <label> Declaraciones: </label> <br/> 
                            <label className='text-primary text-center'> { crmPromotions.numberStatements } </label>
                        </div>
                        <div className='col-4 text-center'>
                            <label> + Declaraciones: </label><br/> 
                            <label className='text-primary text-center'> { crmPromotions.numberStatementsPlus } </label>
                        </div>
                        <div className='col-4 text-center'>
                            <label> Total declaraciones: </label><br/> 
                            <label className='text-primary text-center'> { crmPromotions.totalNumberStatements } </label>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <label className='text-center'> Total en letras: </label><br/> 
                            <label className='text-primary '> { crmPromotions.numberLetter} </label>
                        </div>
                    </div>
                </div>
            );
         } else {
            return (
                <div>
                <div className='row mt-3'>
                    <div className='col-4 text-center'>
                        <label> Precio original: </label> <br/> 
                        <label className='text-primary text-center'> ${dataPromotion.originalPrice?.toFixed(2)} </label>
                    </div>
                    <div className='col-4 text-center'>
                        <label> Descuento de: </label><br/> 
                        <label className='text-primary text-center'> {dataPromotion.discount}% </label>
                    </div>
                    <div className='col-4 text-center'>
                        <label> Total link: </label><br/> 
                        <label className='text-primary text-center'> ${dataPromotion?.totalDiscount?.toFixed(2)} </label>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-4 text-center'>
                        <label> Declaraciones: </label> <br/> 
                        <label className='text-primary text-center'> { dataPromotion.numberStatements } </label>
                    </div>
                    <div className='col-4 text-center'>
                        <label> + Declaraciones: </label><br/> 
                        <label className='text-primary text-center'> { dataPromotion.numberStatementsPlus } </label>
                    </div>
                    <div className='col-4 text-center'>
                        <label> Total declaraciones: </label><br/> 
                        <label className='text-primary text-center'> { dataPromotion.totalNumberStatements } </label>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <label className='text-center'> Total en letras: </label><br/> 
                        <label className='text-primary '> { dataPromotion.numberLetter} </label>
                    </div>
                </div>
            </div>
            )
         }
    }
            
    useEffect(() => {
        if( crmPromotions  ) {
            crmPromotions.isListPromotion = true
        }
    }, [formValues])


    const validateCode = (e) => {
        e.preventDefault();
        setIsActiveModal(true);
      
        // Restablecer los mensajes de error a un objeto vacío antes de realizar las validaciones nuevamente
        setMsgErrorsCode({});
        setMsgErrors({});
      
        if (referenceCode.trim().length === 0) {
          let error = {};
          error['msgCodeValidate'] = 'Es requerido';
          setMsgErrorsCode(error);
          return false;
        }
      
        if (productId.trim().length === 0) {
          if (productId == 0 || productId == null) {
            let error = {};
            error['msgProduct'] = 'Es requerido';
            setMsgErrors(error);
            return false;
          }
        }
      
        dispatch(codeReferencePromotion(productId, referenceCode, setIsActive, setPromotionCodeReference, setProdcutToSalePromotionId, setPromotionCodeProduct));
        setPromotionCodeProduct(crmPromotions);
      };
    const handelBack = ( e ) => {
        e.preventDefault()
        setIsActiveModal(false)
    }    

    const handleSubmit = ( e ) =>{
        e.preventDefault();

        let product = {}
        if (crmPromotions?.isListPromotion === false && crmPromotions?.promotionsToApply?.length === 0) {
            product = {
              gatewayId: 3,
              productToSaleId: prodcutToSalePromotionId,
              emailCustomer: presale.email,
              token: presale.token,
              promotionCode: promotionCodeReference,
              referenceCode: null
            };
          } else if (crmPromotions?.isListPromotion === true && crmPromotions?.promotionsToApply?.length !== 0 && idPromotion !== 0) {
            product = {
              gatewayId: 3,
              productToSaleId: idPromotion.productId,
              emailCustomer: presale.email,
              token: presale.token,
              promotionCode: dataPromotion?.promotionCode,
              referenceCode: promotionCodeProduct
            };
          } else {
            if(productId.trim().length === 0 ){
                if( productId == 0 || productId == null) {
                    let error = {}
                    error['msgProduct']='Es requerido'
                    setMsgErrors(error)
                    return false;
                }
            }
            product = {
              gatewayId: 3,
              productToSaleId: productId,
              emailCustomer: presale.email,
              token: presale.token,
              promotionCode: null,
              referenceCode: null
            };
          }
        dispatch(generatePaymentPreSale(product, handleModal.bind()))
    }

    return (
        <Fragment>
            <div>
                <div className="col-12 mb-3">
                    <div className='bg-primary py-2 px-3 text-white'>
                        <label>Selecciona el producto para: <b> {name} {lastName} </b></label>
                    </div>
                    <div>
                        {
                            crmPromotions && isActiveModal? (
                               ''
                            )
                            :
                            (
                                <TextField
                                className='mt-4'
                                label="Producto" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                                select
                                SelectProps={{
                                native: true,
                                }}
                                name="productId"
                                value={productId}
                                onChange={handleInputChange}
                                helperText={msgErrors.msgProduct}
                                error={msgErrors.msgProduct !== undefined}
                                disabled={  false }
                            >
                                <option value='0'>Selecciona un producto</option>
                                {
                                    productToSales.map((productToSale, j) => {
                                        return (productToSale.productType.key === 'Accounting' && productToSale.isAssisted === true && productToSale.isActive === true && productToSale.frecuency != null &&
                                        <option
                                            key={j}
                                            value={productToSale.id}
                                        >
                                            {productToSale.description} ${productToSale.unitPrice}
                                        </option>
                                        )
                                    })
                                }
                            </TextField>
                            )
                        }

                        {
                            crmPromotions && isActiveModal ? (
                                <>    
                                    <div className='row'>
                                        <div className='col-12'>
                                            {
                                                crmPromotions.promotionCode != '' ? 
                                                (
                                                    <label className='mt-3'>Código de promoción ingresado: <b> { crmPromotions.promotionCode } </b> </label>
                                                )
                                                :
                                                (
                                                    <label className='mt-3'>Código de referencia ingresado: <b> { crmPromotions.referenceCode } </b> </label>
                                                )
                                            }

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-9'>
                                            <TextField
                                                className='mt-4'
                                                label="Promocion disponible"
                                                variant="outlined"
                                                size="small"
                                                autoComplete="off"
                                                fullWidth={true}
                                                select
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                name="idPromotion"
                                                value={idPromotion.idPromotion}
                                                onChange={handleInputChangePromotion}
                                                helperText={''}
                                                error={''}
                                            >   
                                                <option value='0'>Selecciona una promoción</option>
                                                {crmPromotions.promotionsToApply.map((promotion, j) => (
                                                    
                                                    <option 
                                                        key={j} 
                                                        value={promotion.id}
                                                    >
                                                        {promotion.description} - {promotion.totalPromotion} { promotion.promotionType.description}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </div>
                                        <div className='col-3'>
                                            <button 
                                                type='button'
                                                className="btn float-right btn-danger mt-4 ml-4 ml-auto"
                                                onClick={ handelBack }
                                            >
                                                Regresar
                                            </button>
                                        </div> 
                                    </div>
                                 
                                    <br/>
                                    { handleRefresPromotion() }                
                                </>
                            ) 
                            :
                            (
                                <>
                                    <div className='row mt-3'>
                                        <div className='col-7'>
                                            <label> ¿Cuenta con código de referencia? <b> Ingreselo aquí </b></label>
                                            <TextField 
                                                id="outlined-basic" 
                                                name='referenceCode'
                                                label="Codigo" 
                                                value={referenceCode}
                                                variant="outlined"
                                                onChange={ handleInputChangedByReferenceCode } 
                                                fullWidth={ true }
                                                helperText={msgErrorsCode.msgCodeValidate}
                                                error={msgErrorsCode.msgCodeValidate !== undefined}
                                            />
                                        </div>  
                                        <div className='col-2 text-center mt-5'>
                                            <button 
                                                className='btn btn-success rounded mr-2'
                                                style={{ width: '100px', height: '35px' }}
                                                onClick={ validateCode }
                                            >
                                                Validar
                                            </button>
                                        </div>  
                                    </div>
                                </>
                            )
                        }
                        <form onSubmit={ ( e ) => handleSubmit( e , availableProduct)}>
                            <button className="btn float-right btn-primary mt-3">
                                Generar liga
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
